import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import PaymentSafetyBatchTooltip from './PaymentSafetyBatchTooltip'
import { Button, Col, Row } from 'reactstrap';
import { popUp } from '../../../utils';
import axios from 'axios'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

export default function Step4({
  selectedDelivery,
  payOnCard,
  selectedCreditCard,
  wallet,
  payByWallet,
  handleCard,
  handleCash,
  handleWallet,
  setCouponCode,
  disabled,
  setCouponShowModal,
  setCardInfo,
  setSelectedDelivery,
  setSelectedCreditCard,
  setPayByWallet,
  setpayOnCard,
  setUserDetails,
  totalPayableAmount,
  setWalletAmount,
  partialPaymentType
}) {
  const [couponCode, setCode] = useState('')
  const [selectedCoupon, setSelectedCoupon] = useState(null)
  const [readOnly, setReadOnly] = useState(false)
  const ApplyCoupnBtnRef = useRef(null)
  function verifyCoupon() {
    if (!couponCode) {
    } else {
      setCouponCode(couponCode)
      setCouponShowModal(true)
    }
  }
  const [eligibleCoupons, setEligibleCoupons] = useState([])
  const { isBulkUpload, itemsInCart } = useSelector((state) => state.list)

  const loginDetails = localStorage.getItem("login");
  const user = JSON.parse(loginDetails);
  const login = useSelector((state) => state.login);
  const [disabledBtn, setDisableBtn] = React.useState(false);

  const handleClicked = (method) => {
    let body = { defaultPaymentMethod: method };
    setDisableBtn(true);
    axios
      .put(`/api/users/${login?._id}`, body)
      .then((res) => {
        popUp("Your payment method was successfully set as default");
        setUserDetails(res.data?.user?.defaultPaymentMethod)
        localStorage.setItem("login", JSON.stringify(res.data?.user));
        setDisableBtn(false);

        if (method === "cash") {
          handleCash();
          setpayOnCard(false)
          setPayByWallet(false);
          setSelectedCreditCard(false);
          setSelectedDelivery(true);
        } else if (method === "credit-card") {
          handleCard();
          setSelectedCreditCard(true);
          setpayOnCard(true)
          setSelectedDelivery(false);
          setPayByWallet(false);
        }
        else if (method === "wallet") {
          handleWallet();
          setPayByWallet(true);
          setSelectedCreditCard(false);
          setSelectedDelivery(false);
          setpayOnCard(false)
        }
      })
      .catch((e) => {
        popUp(e?.response?.data?.message || "There was a problem with server. Please try again.");
      });
  };

  useEffect(() => {
    var listings = itemsInCart;
    var login =
      typeof localStorage.getItem('login') == 'string'
        ? JSON.parse(localStorage.getItem('login'))
        : localStorage.getItem('login')
    if (listings) {
      axios
        .post('/api/coupon/user-coupons/' + login._id, {
          listings,
        })
        .then((res) =>
          setEligibleCoupons(
            res.data.coupons.map((val, index) => ({
              index: index + 1,
              label: val.name,
              coupon_code: val.coupon_code,
              value: val._id,
              discount_type: val.discount_type.label,
              discount_value: val.discount_type.value,
            }))
          )
        )
        .catch((err) => { })
    }
  }, [])

  useEffect(() => {
    if(payByWallet && !partialPaymentType) {
      if(Number(wallet?.amount?.toFixed(2)) > Number(totalPayableAmount?.toFixed(2))) {
        setWalletAmount(Number(totalPayableAmount?.toFixed(2)))
      } else {
        setWalletAmount(Number(wallet?.amount?.toFixed(2)))
      }
    }
  }, [totalPayableAmount])

  return (
    <div className='payment-step'>
      <div
        className='payment-container bg-white'
        style={{ padding: '1rem 1rem ' }}
      >
        <div className='shipping-content d-flex flex-wrap'>
          <div className='card-payment'
            style={{
              backgroundColor: 'rgb(245, 245, 245)',
              position: 'relative'
            }}
          >
            <div>
              <div className="user-details-container"
                onClick={() => {
                  handleCash();
                  // setUserDetails("cash")
                  setpayOnCard(false)
                  setPayByWallet(false);
                  setSelectedCreditCard(false);
                  setSelectedDelivery(true);
                  setWalletAmount(0)
                }
                }>
                <div className="user-details">
                  <div className={`${selectedDelivery === true ? 'selected-card' : ''} payment-option-payby-cash-card`}  >
                    <input
                      type='radio'
                      checked={selectedDelivery === true}
                      style={{ marginRight: '27px' }}
                    />
                    <span style={{ fontSize: '22px', color: '#4C5036' }}>
                      Pay by Cash/Card <br />
                      Payment on Delivery
                    </span>
                  </div>
                </div>
              </div>
              <hr className="address-hr" />
              <div className="user-details-footer">
                <Button
                  size="sm"
                  color="#FFF"
                  onClick={() => {
                    handleClicked("cash")
                  }}
                  disabled={disabledBtn}
                  className={`btn-address ${user?.defaultPaymentMethod === "cash" ? "visibility-hidden" : ""}`}
                  style={{ marginRight: "0px", marginLeft: "auto" }}
                >
                  Set as default payment method
                </Button>
                <div
                  color="primary"
                  className={user?.defaultPaymentMethod === "cash" ? "address-true" : "d-none"}
                >
                  <p style={{ color: "#fff" }}>Default</p>
                </div>
              </div>
            </div>
          </div>
          {!isBulkUpload && <div className='card-payment'
            style={{
              backgroundColor: 'rgb(245, 245, 245)',
              position: 'relative'
            }}
          >
            <div>
              <div className="user-details-container" onClick={() => {
                handleCard();
                setSelectedCreditCard(true);
                setpayOnCard(true)
                setSelectedDelivery(false);
                setPayByWallet(false);
                setWalletAmount(0)
              }}>
                <div className="user-details">
                  <div
                    className={`${selectedCreditCard === true ? 'selected-card' : ''} payment-option-payby-cash-card`}
                  >
                    <input type='radio' checked={selectedCreditCard === true} />
                    <span
                      style={{
                        fontSize: '22px',
                        color: '#4C5036',
                        width: '140px',
                        marginLeft: '27px',
                      }}
                    >
                      Pay by
                      <br />
                      credit card
                    </span>
                    <div className='card-logo-container'>
                      <img src='/assets/img/Visa.png' />
                      <img
                        src='/assets/img/Maestro.png'
                        style={{ marginTop: '10px' }}
                      />
                    </div>
                    <PaymentSafetyBatchTooltip />
                  </div>
                </div>
              </div>
              <hr className="address-hr" />
              <div className="user-details-footer">
                <Button
                  size="sm"
                  color="#FFF"
                  onClick={() => {
                    handleClicked("credit-card")
                  }}
                  className={`btn-address ${user?.defaultPaymentMethod === "credit-card" ? "visibility-hidden" : ""}`}
                  disabled={disabledBtn}
                  style={{ marginRight: "0px", marginLeft: "auto" }}
                >
                  Set as default payment method
                </Button>
                <div
                  color="primary"
                  className={
                    user?.defaultPaymentMethod === "credit-card" ? "address-true" : "d-none"
                  }
                >
                  <p style={{ color: "#fff" }}>Default</p>
                </div>
              </div>
            </div>
          </div>}
          {wallet?._id && wallet?.amount > 0 ? <div className='card-payment'
            style={{
              backgroundColor: 'rgb(245, 245, 245)',
              position: 'relative'
            }}
          >
            <div >
              <div className="user-details-container" onClick={() => {
                handleWallet();
                setPayByWallet(true);
                setSelectedCreditCard(false);
                setSelectedDelivery(false);
                setpayOnCard(false)
              }}>
                <div className="user-details">
                  <div className={`${payByWallet === true ? 'selected-card' : ''} payment-option-payby-cash-card`}>
                    <input type='radio' checked={payByWallet === true} />
                    <span
                      style={{
                        fontSize: '22px',
                        color: '#4C5036',
                        width: '140px',
                        marginLeft: '27px',
                      }}
                    >
                      Pay by
                      <br />
                      Wallet
                    </span>
                    <div className='card-logo-container'>
                      <img
                        src='/assets/img/payment-icons/credit.png'
                        style={{ height: 50, width: 50 }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr className="address-hr" />
              <div className="user-details-footer">
                <Button
                  size="sm"
                  color="#FFF"
                  onClick={() => {
                    handleClicked("wallet")
                  }}
                  className={`btn-address ${user?.defaultPaymentMethod === "wallet" ? "visibility-hidden" : ""}`}
                  style={{ marginRight: "0px", marginLeft: "auto" }}
                  disabled={disabledBtn}
                >
                  Set as default payment method
                </Button>
                <div
                  color="primary"
                  className={
                    user?.defaultPaymentMethod === "wallet" ? "address-true" : "d-none"
                  }
                >
                  <p style={{ color: "#fff" }}>Default</p>
                </div>
              </div>
            </div>
          </div> : null}
        </div>
      </div>
      {/* <div style={{}}>
        <h3
          style={{
            paddingLeft: '0.8rem',
          }}
          className='mt-4'
        >
          Apply Coupon:
        </h3>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: '3px',
          }}
        >
          <Autocomplete
            disablePortal
            freeSolo
            value={selectedCoupon}
            inputValue={couponCode}
            onInputChange={(event, newValue, reason) => {
              if (reason !== 'reset') {
                if (reason === 'clear') {
                  setCode(newValue)
                  setReadOnly(false)
                } else {
                  if (!readOnly) {
                    setCode(newValue)
                  }
                }
              }
            }}
            onChange={(event, newValue, reason) => {
              if (reason === 'createOption') {
                if (newValue) {
                  return ApplyCoupnBtnRef.current.click()
                }
              } else {
                if (newValue) {
                  setCode(newValue.coupon_code)
                  setReadOnly(true)
                } else {
                  setCode(newValue)
                }
              }
            }}
            getOptionLabel={(option) => option.coupon_code}
            autoHighlight
            forcePopupIcon
            options={eligibleCoupons}
            renderOption={(props, option) => (
              <Box
                component='li'
                {...props}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                }}
              >
                <div style={{ fontWeight: 'bold', fontSize: 13 }}>
                  {option.coupon_code}
                </div>
                <div style={{ fontWeight: 'bold' }}>
                  Discount: {option.discount_value}
                  <span style={{ fontWeight: 'bold' }}>
                    {option.discount_type !== 'by_percent' ? ' AED' : '%'}
                  </span>
                </div>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder='Select or Enter Coupon Code'
                variant='outlined'
                autoComplete='new-password'
              />
            )}
          />
          <button
            className='btn btn-primary text-center'
            disabled={!Boolean(couponCode)}
            ref={ApplyCoupnBtnRef}
            type='button'
            onClick={verifyCoupon}
            style={{
              backgroundColor: 'rgb(243 147 44)',
              height: '52px',
              cursor: 'pointer',
            }}
          >
            Apply
          </button>{' '}
        </div>
      </div> */}
    </div>
  )
}
