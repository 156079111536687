import { toast } from 'react-toastify'
import React, { useState, useEffect } from 'react'
import ReactGA from 'react-ga4'
import moment from 'moment'
import { carLogoData } from './constants'
import { store } from '.'

export function getTaxAmount(totalPrice) {
  return (totalPrice - totalPrice / 1.05).toFixed(2)
}
export const numberWithCommas = (x) => {
  if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  else return 0
}

export const getDiscountAmount = (
  price,
  qty,
  listing,
  discountPerUnit,
  coupon
) => {
  let discountAmount = 0
  if (
    coupon?.parameters?.label === 'Category' &&
    coupon?.parameters?.value?.includes(listing.category)
  ) {
    discountAmount = discountPerUnit * price * qty
  }
  if (
    coupon?.parameters?.label === 'Brand' &&
    coupon?.parameters?.value?.includes(listing.partBrand)
  ) {
    discountAmount = discountPerUnit * price * qty
  }

  if (
    coupon?.parameters?.label === 'Sub-Category' &&
    coupon?.parameters?.value?.includes(listing.subCategory)
  ) {
    discountAmount = discountPerUnit * price * qty
  }
  if (
    coupon?.parameters?.label === 'SKU' &&
    !coupon?.parameters?.selectedAll &&
    coupon?.parameters?.value?.includes(listing._id)
  ) {
    discountAmount = discountPerUnit * price * qty
  }
  if (
    coupon?.parameters?.label === 'SKU' &&
    coupon?.parameters?.selectedAll &&
    !coupon?.excludedSKUs?.includes(listing._id)
  ) {
    discountAmount = discountPerUnit * price * qty
  }
  if (
    coupon?.parameters?.label === 'Seller' &&
    coupon?.parameters?.value?.includes(listing?.user?._id || listing?.user)
  ) {
    discountAmount = discountPerUnit * price * qty
  }
  return discountAmount
}


export const stringToUrl = (str) => {
  if (str)
    return str
      .toString()
      .replaceAll('%', '')
      .toLowerCase()
      .replaceAll(' ', '-')
      .replaceAll('/', '-')
  else return null
}

export const UserAvatar = (props) => {
  const [image, setImage] = useState('/assets/img/avatar.png')
  const { avatar } = props
  useEffect(() => {
    if (avatar) {
      setImage(avatar)
    }
  }, [avatar])
  function handleErr() {
    setImage('/assets/img/avatar.png')
  }
  return <img src={image} onError={handleErr} {...props} alt =""/>
}

export const ProductImage = ({ avatar, style = null }) => {
  const [image, setImage] = useState('/assets/img/byparts.jpg')

  useEffect(() => {
    if (avatar) {
      setImage(avatar)
    }
  }, [avatar])

  function handleErr() {
    setImage('/assets/img/byparts.jpg')
  }
  return (
    <>
      <img src={image} style={style} onError={handleErr} alt ="" />
    </>
  )
}

export const validateFile = (file) => {
  if (!file) return true
  const isLt5M = file?.size / 1024 / 1024 < 5
  if (!isLt5M) {
    popUp('File must smaller than 5MB!')
    return false
  } else {
    return true
  }
}

export const getCartLength = (items, value) => {
  if (value && value?.orderQty) {
    return value?.orderQty
  }
  let count = 0
  for (let i = 0; i < items.length; ++i) {
    if (items[i] === value?._id) count++
  }
  return count
}

export function formatPhoneNumber(phone) {
  return [
    phone.slice(0, 4),
    ' ',
    phone.slice(4, 6),
    ' ',
    phone.slice(6, 9),
    ' ',
    phone.slice(9, phone.length),
  ].join('')
}

export const getTimeSince = (date) => {
  const seconds = Math.floor((new Date() - date) / 1000)

  let interval = seconds / 31536000

  if (interval > 1) {
    return Math.floor(interval) + ' years'
  }
  interval = seconds / 2592000
  if (interval > 1) {
    return Math.floor(interval) + ' months'
  }
  interval = seconds / 604800
  if (interval > 1) {
    return Math.floor(interval) + ' weeks'
  }
  interval = seconds / 86400
  if (interval > 1) {
    if (Math.floor(interval) === 1) return Math.floor(interval) + ' day'
    return Math.floor(interval) + ' days'
  }
  interval = seconds / 3600
  if (interval > 1) {
    return Math.floor(interval) + ' hours'
  }
  interval = seconds / 60
  if (interval > 1) {
    return Math.floor(interval) + ' minutes'
  }
  return Math.floor(seconds) + ' seconds'
}

export const popUp = (message, btnText, url, history) => {
  let showM = (
    <div className='d-flex  align' style={{ gap: '6px', fontSize: '12px' }}>
      <span>
        {message
          ? message
          : 'There was a problem with server. Please try again.'}
      </span>
      {btnText && (
        <span
          style={{ color: '#ff8a3b' }}
          onClick={(e) => {
            e.preventDefault()
            history ? history.push(url) : (window.location.href = `${url}`)

            if (url === '/checkout') {
              ga4Events({
                action: 'notification_popup_view_cart',
                category: 'Cart',
              })
            }
          }}
        >
          {btnText}{' '}
        </span>
      )}
    </div>
  )
  toast.success(showM)
}

export function cbm(h, w, l) {
  return (h / 1000) * (w / 1000) * (l / 1000)
}

export function clickablePopUp(text, status = 'success', to, history) {
  return toast(
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <img
        src='/assets/img/logo-icon.png' alt='logo'
        style={{ height: 60, marginRight: 20 }}
      />
      <div>
        <p
          style={{
            fontSize: 18,
            marginBottom: 0,
            fontWeight: 'bold',
            color: status === 'error' ? 'rgb(243, 146, 0)' : '#1b0451',
          }}
        >
          {status === 'error'
            ? 'Oops'
            : status === 'thankyou'
              ? 'Thank you'
              : 'Success'}
        </p>
        <p style={{ marginBottom: 0, lineHeight: 1.4 }}>{text}</p>
        <p onClick={() => history.push(to)} style={{ color: '#1d71b8' }}>
          Click Here !
        </p>
      </div>
    </div>
  )
}
export const changeToGroup = (rows) => {
  const data = rows.reduce(function (rv, x) {
    ; (rv[x.user?.details?.company_name] =
      rv[x.user?.details?.company_name] || []).push(x)
    return rv
  }, {})
  let i = 0
  const group = []
  for (const key in data) {
    group.push({ sellerName: key, data: data[key] })
    // eslint-disable-next-line
    i++
  }
  return group
}

export const changeOrderToGroup = (rows) => {
  const data = rows.reduce(function (rv, x) {
    ; (rv[x.seller?.details?.company_name] =
      rv[x.seller?.details?.company_name] || []).push(x)
    return rv
  }, {})
  let i = 0
  const group = []
  for (const key in data) {
    group.push({
      sellerName: key,
      data: data[key],
      sellerId: data[key][0]?.seller?._id,
      aliasName: data[key][0]?.seller?.aliasName || ""
    })
    // eslint-disable-next-line
    i++
  }
  return group
}

export const validPassword = new RegExp(
  '/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/'
)

export const getGarageType = (type) => {
  switch (type) {
    case 'Authorized':
    case 'Authorized Garages':
    case 'Fleet & Corporate':
      return 'Fleet & Corporate'
    case 'Chained/Third-party [Independent] Garages':
    case 'Chained/Third-party [Independent] garages':
    case 'Chained/Third-party Garages':
      return 'Chained/Third-party [Independent] Garages'
    case 'Independent Mom & Pop Garages':
    case 'Independent Garages':
    case 'Independent mom & pop Garages':
      return 'Independent Mom & Pop Garages'
    case 'Gas Station Network':
      return 'Gas Station Network'
    case 'Tyre & Brake Outlets':
      return 'Tyre & Brake Outlets'
    case 'Unauthorized Garages':
    case 'Fleet/Corporate Garages':
    case 'Spare Parts & Traders':
    case 'unauthorized':
      return 'Spare Parts & Traders'
    default:
      return ''
  }
}

export const getAmountWithoutVat = (amountIncludingVat, Vat) => {
  var amountExVat = (amountIncludingVat / (100 + Vat)) * 100
  var sumVat = amountIncludingVat - amountExVat
  var amounts = {
    priceExVat: amountExVat.toFixed(2),
    vat: sumVat.toFixed(2),
  }

  return amounts
}

export const ga4Events = ({ action, category, data, ...params }) => {
 if (action) {
    ReactGA.event(action, {
     event_category: category,

      ...params,
      data
    })
  }
}

export const handleBannerEvents = (action, banner) => {
  let events = {
    action,
    category: "Banner",
    banner_id: banner?._id
  }

  if (banner?.collectionId) {
    events = {
      ...events,
      collection_id: banner?.collectionId?._id,
      collection_name: banner?.collectionId?.title
    }
  }

  ga4Events(events)
}

export const handleHomepageSectionEvents = (data, sectionData) => {
  let events = {
    action: "homepage_section_click",
    category: "Homepage",
    section_id: data?._id,
    section_name: data?.title,
    section_type: data?.sectionType,
    section_model: data?.sectionModel,
    ...sectionData
  }

  ga4Events(events)
}

export const calcInclVatFromExcl = ({ exclPrice, vatPercent }) => {
  const inclPrice = exclPrice * (1 + vatPercent / 100)
  const taxAmount = inclPrice - exclPrice
  return { inclPrice, taxAmount }
}

export const calcExclVatFromIncl = ({ inclPrice, vatPercent }) => {
  const exclPrice = inclPrice / (1 + vatPercent / 100)
  const taxAmount = inclPrice - exclPrice
  return { exclPrice, taxAmount }
}

export const calcOrderSummary = ({
  orderDetail: order,
  returnProduct,
  currentUser: { _id, role },
}) => {
  let listings = order.products || []
  let coupon = order?.coupon || {}
  let generalRoles = ['admin', 'logistics_admin', 'finance', 'BUYER']
  let shippingPrice = generalRoles?.includes(role)
    ? order?.shipping_price || 0
    : 0

  let allExpressOrderListSKU = []
  let discountableProducts = listings?.filter((l) => {
 
    const {
      delivery_option: { _id, delivery_option: option },
      status,
    } = l
    if (option === 'express') {
      if (status !== 'cancelled') {
        allExpressOrderListSKU.push({
          qty: l.quantity,
          _id,
        })
      }
    }
    if (
      coupon?.parameters?.label === 'Category' &&
      coupon?.parameters?.value?.includes(l.delivery_option?.category)
    ) {
      return l
    }
    if (
      coupon?.parameters?.label === 'Sub-Category' &&
      coupon?.parameters?.value?.includes(l.delivery_option?.subCategory)
    ) {
      return l
    }
    if (
      coupon?.parameters?.label === 'Brand' &&
      coupon?.parameters.value?.includes(l.delivery_option?.partBrand)
    ) {
      return l
    }
    if (
      coupon?.parameters?.label === 'SKU' &&
      !coupon?.parameters?.selectedAll &&
      coupon?.parameters?.value?.includes(l?.delivery_option?._id)
    ) {
      return l
    }
    if (
      coupon?.parameters?.label === 'SKU' &&
      coupon?.parameters?.selectedAll &&
      !coupon?.excludedSKUs?.includes(l?.delivery_option?._id)
    ) {
      return l
    }
    if (
      coupon?.parameters?.label === 'Seller' &&
      coupon?.parameters?.value?.includes(l?.delivery_option?.user?._id)
    ) {
      return l
    }
     
  })
  let initialDiscountableProductsSumWithOutTax = discountableProducts.reduce(
    (a, b) => {
      return a + b?.delivery_option?.priceExclVat * b.quantity
    },
    0
  )
  let latestDiscountableProductsSumWithOutTax = discountableProducts.reduce(
    (a, b) => {
      if (b.status !== 'cancelled') {
        return a + b?.delivery_option?.priceExclVat * b.quantity
      }
      return a
    },
    0
  )

  let returnProductSum = returnProduct.reduce((a, b) => {
    if (b.status === 'quality_check_pass') {
      const {
        quantity,
        list: { _id: id },
      } = b
      const index = allExpressOrderListSKU.findIndex(
        ({ _id, qty }) => id === _id
      )
      if (allExpressOrderListSKU[index]) {
        allExpressOrderListSKU[index]['qty'] =
          allExpressOrderListSKU[index]['qty'] - quantity
      }
      return a + b?.list?.priceExclVat * b?.quantity
    }
    return a
  }, 0)

  let cancelledProductSum = listings?.reduce(
    (a, b) =>
      b?.status === 'cancelled'
        ? a + b?.delivery_option?.priceExclVat * b?.quantity
        : a,
    0
  )
  let isShipingAvailable = allExpressOrderListSKU.reduce((a, { _id, qty }) => {
    return a + qty
  }, 0)
  if (!isShipingAvailable) {
    // setting shippingPrice zero if no express item is in list
    shippingPrice = 0
  }
  let shippingPriceWithoutTax = shippingPrice / 1.05

  let groupRoleBaseProducts = listings.filter((item, index) => {
    if (role === 'SELLER' && item?.delivery_option?.user?._id === _id) {
      return true
    } else if (generalRoles.includes(role)) {
      return true
    } else {
      return false
    }
  })
  let roleBasedReturnProduct = returnProduct.filter((item, index) => {
    if (role === 'SELLER' && item?.status === 'quality_check_pass') {
      return item.list?.user?._id === _id || item.list?.user === _id
    } else if (
      generalRoles.includes(role) &&
      item?.status === 'quality_check_pass'
    ) {
      return true
    } else {
      return false
    }
  })
  let roleBasedCancelProduct = listings.filter((b, i) => {
    if (
      b?.status === 'cancelled' &&
      role === 'SELLER' &&
      b?.delivery_option?.user?._id === _id
    ) {
      return true
    } else if (b?.status === 'cancelled' && generalRoles.includes(role)) {
      return true
    } else {
      return false
    }
  })
  let initialRoleBasedSubtotal = groupRoleBaseProducts.reduce(
    (a, b) => a + b?.delivery_option?.priceExclVat * b?.quantity,
    0
  )
  let roleBasedReturnProductsSum = roleBasedReturnProduct.reduce(
    (a, b) => a + b?.list?.priceExclVat * b?.quantity,
    0
  )
  let roleBasedCancelledProductsSum = roleBasedCancelProduct.reduce(
    (a, b) => a + b?.delivery_option?.priceExclVat * b?.quantity,
    0
  )

  let latestRoleBasedSubTotal =
    initialRoleBasedSubtotal -
    roleBasedReturnProductsSum -
    roleBasedCancelledProductsSum

  // for calculating discountPerUnit
  let initialSubTotal = listings.reduce((a, b) => {
    return a + b.delivery_option?.priceExclVat * b.quantity
  }, 0)

  let latestSubTotal = initialSubTotal - returnProductSum - cancelledProductSum

  // calculate discount per unit
  let { discountPerUnit, initialDiscountPerUnit } =
    getInitialAndLatestDisPerUnit({
      latestSubTotal,
      initialSubTotal,
      initialDiscountableProductsSumWithOutTax,
      latestDiscountableProductsSumWithOutTax,
      coupon,
    })
  let groupproducts = [],
    cancelproducts = []
  groupRoleBaseProducts.forEach((item, i) => {
    if (item.status !== 'cancelled') {
      groupproducts.push(item)
    } else {
      cancelproducts.push(item)
    }
  })

  let taxAmount = 0
  let discountAmtOfReturn = roleBasedReturnProduct
    .map((o, i) => {
      let exclPrice = 0
      let totalPrice = 0
      let discountAmount = 0
      let newTotalAfterDiscount = 0
      if (o?.status === 'quality_check_pass') {
        exclPrice = o?.list?.priceExclVat
        totalPrice = exclPrice * o?.quantity
        discountAmount = getDiscountAmount(
          exclPrice,
          o?.quantity,
          o?.list,
          discountPerUnit,
          coupon
        )
        newTotalAfterDiscount = totalPrice - discountAmount
        taxAmount =
          taxAmount + newTotalAfterDiscount * (o?.list.vatPercent / 100)
      }
      return discountAmount
    })
    .reduce((a, b) => {
      return a + b
    }, 0)

  // calculating total new amount for all products
  let initialRoleBasedGrandTotal = groupRoleBaseProducts?.map((item) => {
        let exclPrice = item?.delivery_option?.priceExclVat
        let totalUnitPrice =
          exclPrice * item?.quantity + item?.shipping_price_individual
        let discountAmount = getDiscountAmount(
          exclPrice,
          item?.quantity,
          item?.delivery_option,
          initialDiscountPerUnit,
          coupon
        )
        let newTotalAfterDiscount = totalUnitPrice - discountAmount
        let taxAmount =
          newTotalAfterDiscount * (item?.delivery_option.vatPercent / 100)

        let newTotalCost = taxAmount + newTotalAfterDiscount
        let netAmount = totalUnitPrice - discountAmount + taxAmount
        return netAmount
      })
      .reduce((a, b) => a + b, 0) + shippingPriceWithoutTax;

  let roleBasedDiscountableProductsAndReturnedSum = discountableProducts?.reduce((a, b) => {
      let returnedSum = returnProduct?.reduce((a, item) => {
        if (
          b?.delivery_option?._id === item.list?._id &&
          item.status === 'quality_check_pass'
        ) {
          return a + item.list?.priceExclVat * item.quantity
        }
        return a
      }, 0)
      let isAssigned = false
      if (role === 'SELLER') {
        isAssigned = b?.delivery_option?.user?._id === _id ? true : false
      } else if (generalRoles.includes(role)) {
        isAssigned = true
      } else {
        isAssigned = false
      }
      if (isAssigned) {
        return a + returnedSum
      } else {
        return a
      }
    }, 0)

  let roleBasedDiscountableProductsAndCancelledSum =
    discountableProducts?.reduce((a, b) => {
      let isAssigned = false
      if (role === 'SELLER') {
        isAssigned = b?.delivery_option?.user?._id === _id ? true : false
      } else if (generalRoles.includes(role)) {
        isAssigned = true
      } else {
        isAssigned = false
      }
      if (b.status === 'cancelled' && isAssigned) {
        return a + b.delivery_option?.priceExclVat * b.quantity
      } else {
        return a
      }
    }, 0)

  let initalRoleBasedDiscountableProductsSum = discountableProducts.reduce(
    (a, b) => {
      if (role === 'SELLER' && b?.delivery_option?.user?._id === _id) {
        return a + b?.delivery_option?.priceExclVat * b?.quantity
      } else if (generalRoles.includes(role)) {
        return a + b?.delivery_option?.priceExclVat * b?.quantity
      } else {
        return a
      }
    },
    0
  )

  let latestRoleBasedDiscountableProductsSumWithoutTax =
    initalRoleBasedDiscountableProductsSum -
    roleBasedDiscountableProductsAndReturnedSum -
    roleBasedDiscountableProductsAndCancelledSum

  let latestRoleBasedDiscountAmount =
    latestRoleBasedDiscountableProductsSumWithoutTax * discountPerUnit

  let latestTaxWithoutCancel = groupproducts.reduce((a, item) => {
    let exclPrice = item?.delivery_option?.priceExclVat
    let totalUnitPrice =
      exclPrice * item?.quantity + item?.shipping_price_individual
    let discountAmount = getDiscountAmount(
      exclPrice,
      item?.quantity,
      item?.delivery_option,
      discountPerUnit,
      coupon
    )
    let newTotalAfterDiscount = totalUnitPrice - discountAmount
    let taxAmount =
      newTotalAfterDiscount * (item?.delivery_option.vatPercent / 100)
    return a + taxAmount
  }, 0)

  let latestTaxForReturnedProducts = returnProduct
    .map((o, i) => {
      let exclPrice = 0
      let totalPrice = 0
      let discountAmount = 0
      let newTotalAfterDiscount = 0
      let taxAmount = 0
      if (o?.status === 'quality_check_pass') {
        exclPrice = o?.list?.priceExclVat
        totalPrice = exclPrice * o?.quantity
        discountAmount = getDiscountAmount(
          exclPrice,
          o?.quantity,
          o?.list,
          discountPerUnit,
          coupon
        )
        newTotalAfterDiscount = totalPrice - discountAmount
        taxAmount = newTotalAfterDiscount * (o?.list.vatPercent / 100)
      }
      return taxAmount
    })
    .reduce((a, b) => {
      return a + b
    }, 0)

  let latestTax =
    latestTaxWithoutCancel -
    latestTaxForReturnedProducts +
    shippingPriceWithoutTax * 0.05

  let latestRoleBasedGrandTotal =
    latestRoleBasedSubTotal -
    latestRoleBasedDiscountAmount +
    shippingPriceWithoutTax +
    latestTax

  let initalDiscountAmount =
    initialDiscountableProductsSumWithOutTax * initialDiscountPerUnit

  let refundAmt =
    initialRoleBasedGrandTotal -
    latestRoleBasedGrandTotal -
    (roleBasedCancelledProductsSum + shippingPriceWithoutTax)

  return {
    discount: latestRoleBasedDiscountAmount,
    discountPerUnit,
    actual_total_price: latestRoleBasedGrandTotal,
    tax: latestTax,
    actualSubTotal: latestRoleBasedSubTotal,
    shipping_price: shippingPrice / 1.05,
    totalOrderAmt: initialRoleBasedGrandTotal,
    remainingOrderAmt: latestRoleBasedGrandTotal,
    refundAmt,
    initalDiscountAmount,
    initalDiscountPerUnit: initialDiscountPerUnit,
    initialDiscount: discountAmtOfReturn,
  }
}

function getInitialAndLatestDisPerUnit({
  latestSubTotal,
  initialSubTotal,
  coupon,
  initialDiscountableProductsSumWithOutTax,
  latestDiscountableProductsSumWithOutTax,
}) {
  let initialDiscountPerUnit = 0
  let discountPerUnit = 0
  if (initialSubTotal >= coupon?.min_cart_value) {
    if (coupon?.discount_type?.label === 'by_percent') {
      initialDiscountPerUnit = coupon?.discount_type?.value / 100
    }
    if (coupon?.discount_type?.label === 'by_value') {
      initialDiscountPerUnit =
        coupon?.discount_type?.value / initialDiscountableProductsSumWithOutTax
    }
  }
  if (latestSubTotal >= coupon?.min_cart_value) {
    if (coupon?.discount_type?.label === 'by_percent') {
      discountPerUnit = coupon?.discount_type?.value / 100
    }
    if (coupon?.discount_type?.label === 'by_value') {
      discountPerUnit =
        coupon?.discount_type?.value / latestDiscountableProductsSumWithOutTax
    }
  }

  // check if coupon discount amt valid to discountAmnt
  let initialDiscountAmnt =
    initialDiscountableProductsSumWithOutTax * initialDiscountPerUnit

  let latestDiscountAmnt =
    latestDiscountableProductsSumWithOutTax * discountPerUnit

  if (coupon?.discount_cap_amount) {
    initialDiscountPerUnit =
      initialDiscountAmnt < coupon?.discount_cap_amount
        ? initialDiscountPerUnit
        : coupon?.discount_cap_amount / initialDiscountableProductsSumWithOutTax
    discountPerUnit =
      latestDiscountAmnt < coupon?.discount_cap_amount
        ? discountPerUnit
        : coupon?.discount_cap_amount / latestDiscountableProductsSumWithOutTax
  }

  return {
    initialDiscountPerUnit,
    discountPerUnit,
  }
}

function totalWithIncTax(list, discountPerUnit, coupon) {
  return list
    ?.map((item, i) => {
      let exclPrice = item?.delivery_option?.priceExclVat
      let totalUnitPrice = exclPrice * item?.quantity
      let discountAmount = getDiscountAmount(
        exclPrice,
        item?.quantity,
        item?.delivery_option,
        discountPerUnit,
        coupon
      )
      // newTaxAMount
      let newTotalAfterDiscount = totalUnitPrice - discountAmount
      let taxAmount =
        newTotalAfterDiscount * (item?.delivery_option.vatPercent / 100)
      let netAmount = totalUnitPrice - discountAmount + taxAmount
      return { netAmount, taxAmount }
    })
    .reduce(
      (a, b) => {
        let { netAmount, taxAmount } = a
        return {
          netAmount: netAmount + b.netAmount,
          taxAmount: taxAmount + b.taxAmount,
        }
      },
      { netAmount: 0, taxAmount: 0 }
    )
}
export const formatString = (str) => {
  str = str.toLowerCase() // convert string to lowercase
  str = str.replace(/-/g, ' ') // replace all hyphens with spaces
  str = str.replace(/^\w|\s\w/g, (c) => c.toUpperCase()) // capitalize first letter of each word
  return str
}

export const getCarBrandLogo = ({ brandName }) => {
  let _brandName = brandName.toLowerCase()

  let index = carLogoData.findIndex((value) => _brandName.includes(value))

  if (index !== -1) {
    _brandName = carLogoData[index]
  }

  _brandName = _brandName.replace(' ', '-')

  const uri = `/assets/img/make-logos/${_brandName}.png`

  return uri
}

export const ignoredUrls = ["https://apilayer.net"]

export const isValidObject = (value) => {
  return typeof value === "object" && value !== null && !Array.isArray(value)
}
export const getCurrentTime = () => {
  const currentDate = new Date();
  // Get individual components of the current date and time
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth(); // 0-based index (0 = January)
  const day = currentDate.getDate();
  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();
  const seconds = currentDate.getSeconds();
  // Format the date and time as a string
  const formattedDateTime = `${year}-${month + 1}-${day} ${hours}:${minutes}:${seconds}`;
  return formattedDateTime;
};

export const sortArrayList = ({ list, type = "object" }) => {
  if (!list?.length) {
    return []
  }

  if (type === "object") {
    list?.sort((a, b) => {
      if (a.name < b.name) { return -1; }
      if (a.name > b.name) { return 1; }
      return 0;
    })
  }

  if (type === "string") {
    list?.sort((a, b) => {
      if (a < b) { return -1; }
      if (a > b) { return 1; }
      return 0;
    });
  }
  return list;
}

export const getListingDeliveryTime = ({ business_model, quantity, bmDTs }) => {
  let deliveryTime = ""
  const { defaultDeliveryTime } = store.getState()?.settings;

 // if (business_model !== "Consolidation" && quantity > 0) {
    if ( quantity > 0) {
    //To find the delivery time based on seller business model and sku business model
    let deliveryTime = bmDTs?.find(bmDT => bmDT?.business_model === business_model);
    deliveryTime = deliveryTime?.delivery_time?.name ?? ""

    if (!deliveryTime) {
      //To find the default delivery time based on business model of sku
      deliveryTime = defaultDeliveryTime?.find(deliveryTime => deliveryTime?.business_model === business_model);
      deliveryTime = deliveryTime?.name
    }
    return deliveryTime
  }
  return deliveryTime;
}

export const countIdsWithQuantity = (arr) => {
  const idCount = {};
  arr?.length && arr?.forEach((id) => {
    idCount[id] = (idCount[id] || 0) + 1;
  });
  const resultArray = Object.keys(idCount).map((id) => ({
    id,
    quantity: idCount[id]
  }));
  return resultArray;
}

export const checkAndLogExceedLimit = (clickedItemId, array, quantity) => {
  const foundItem = array?.find(item => item.id?.toString() === clickedItemId?.toString());
  if (foundItem && foundItem.quantity >= quantity) {
    return true;
  } else {
    return false;
  }
}

export const checkAndLogExceedLimitForEqual = (clickedItemId, array, quantity) => {
  const foundItem = array?.find(item => item.id?.toString() === clickedItemId?.toString());
  if (foundItem && foundItem.quantity > quantity) {
    return true;
  } else {
    return false;
  }
}

export const isDateExceedsDays = (dateString, noOfDays) => {
   const inputDate = new Date(dateString);
   const currentDate = new Date();
   const timeDifference = currentDate - inputDate;
   const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
   return daysDifference < noOfDays;
}
