import React, { Fragment, Component } from 'react'
import { compose } from 'redux'
import axios from 'axios'
import LoadingOverlay from 'react-loading-overlay'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import SimpleReactValidator from 'simple-react-validator'
import Modal from 'react-awesome-modal'
import queryString from 'query-string'
import Gallery from '../carousel/gallery'
import StarRatingComponent from 'react-star-rating-component'
import { checkAndLogExceedLimit, checkAndLogExceedLimitForEqual, checkQuantity, countIdsWithQuantity, ga4Events, getCartLength, getListingDeliveryTime, popUp, ProductImage } from '../../utils'
import {
  UpdateCart,
  getSameNameItem,
  visitor,
  setPathname,
  addMultipleItemsInCart,
} from '../../Store/action/listingActions'
import {
  NewQuotation,
  UpdateQuotationItems,
  ClearQuotation,
} from '../../Store/action/quotationActions'
import { ListingFetures } from '../content/element/listing-features'
import CallbackDetails from '../content/element/modal/callback-details'
import { SetLoading } from '../../Store/action/listingActions'
import { SetActiveRoom, Initialize } from '../../Store/action/chatActions'
import { AddToCart } from '../../Store/action/listingActions'
import Review from '../content/element/Review/index'
import ItemReview from '../content/element/ItemReview'
import { CardModal } from '../content/element/card/card-listing-grid'
import NotLoggedInModal from '../content/element/modal/not-logged-modal'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import { BreadcrumbCategory } from '../content/element/breadcrumb'
import MultisellerDrawer from '../content/element/MultisellerDrawer'
import LikeListingGrid from '../content/element/card/like-listing-grid'
import { numberWithCommas, stringToUrl } from '../../utils'
import VisitorModal from '../content/element/modal/visitor-modal'
import ShowDynamicPrice from '../common/ShowDynamicPrice'
import ShowDynamicCurrency from '../common/ShowDynamicCurrency'
import { AdditionalNotes } from '../content/element/additional-notes'
import { ListingManufacture } from '../content/element/listing-manufacture'
import { ServiceDescription } from '../content/element/service-description'
import { Share } from '@material-ui/icons'
import ShareModal from '../carousel/ShareModal'
import TextOverlay from '../common/TextOverlay'

class ListingDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      listing: null,
      listing_user: null,
      seller_listing: 0,
      modalIsOpen: false,
      similarListings: null,
      listingDetailTabIndex: 1,
      showQuotationConfirm: false,
      isOpenReviewModal: false,
      reviewData: [],
      loading: false,
      isOpen: false,
      modalshow: false,
      imageArray: '',
      partName: '',
      partSKU: '',
      price: '',
      priceExclVat: '',
      _id: '',
      date: '',
      description: '',
      title: '',
      badge: '',
      link: '',
      url: '',
      apiSendNo: '',
      comment: '',
      ratingStar: 0,
      totalStar: 0,
      showModal: false,
      disableBtn: false,
      isDrawerOpen: false,
      cartQuantity: [],
      pendingState: false,
      hasLoggedUserReviewed: false,
      userReviewForEdit: '',
      showVisitorModal: true,
      disableIncrement: false
    }
    this.validator = new SimpleReactValidator()
    this.getListing = this.getListing.bind(this)
    this.startChat = this.startChat.bind(this)
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.qtyDecrement = this.qtyDecrement.bind(this)
    this.qtyIncrement = this.qtyIncrement.bind(this)
    this.isReviewModal = this.isReviewModal.bind(this)
    this.modalclose = this.modalclose.bind(this)
    this.handleNotWorkingmodal = this.handleNotWorkingmodal.bind(this)
    this.handleToggleDrawer = this.handleToggleDrawer.bind(this)
    this.closeVisitorModal = this.closeVisitorModal.bind(this)
    this.toggle = this.toggle.bind(this);
    this.apiCallCount = 1;
    this.apiCallLimit = this.state.listing?.quantity; // Set
  }

  startChat(e) {
    e.preventDefault()
    const { listing, listing_user } = this.state
    if (this.props.login?._id === listing_user?._id) return
    axios
      .post('/api/chat-rooms/add', {
        buyer_id: this.props.login?._id,
        listing_id: listing?._id,
        seller_id: listing_user?._id,
      })
      .then((res) => {
        const { activeRoomId } = res.data
        this.props.chatRoomInitialize().then(() => {
          this.props.setChatRoom(activeRoomId).then(() => {
            ga4Events({
              action: 'pdp_start_discussion',
              category: 'Listing',
              product_id: listing?._id,
              product_name: listing?.partName,
            })

            this.props.history.push({
              pathname: '/messages',
              state: { detail: activeRoomId },
            })
          })
        })
      })
      .catch((err) => {
        popUp('There was a problem with server. Please try again.')
      })
  }

  getListingSKU() {
    const info = this.props.match.params.info.split('--')
    const sku = info[info.length - 1]
    return sku
  }


  componentDidMount() {
    this.getListing(this.getListingSKU());
    // this.props.sameNameItemList("HEADLIGHT XENON");
  }

  getReviews = (id) => {
    axios
      .get(`/api/review/${id}`)
      .then((res) => {
        const currentUser = JSON.parse(localStorage.getItem('login'))
        const hasUserReview = res.data.find(
          (rev) => rev.user._id === currentUser._id
        )
        this.setState({
          userReviewForEdit: hasUserReview,
          reviewData: res.data,
          hasLoggedUserReviewed: !!hasUserReview,
          comment: hasUserReview?.comment,
          ratingStar: hasUserReview?.star,
        })
      })
      .catch((err) => {
        popUp('There was a problem with server. Please try again.')
      })
  }

  componentDidUpdate(prevProps, prevState) {
    const { listing } = this.state
    const params = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })

    if (
      listing &&
      listing.partSKU.toLowerCase() !== this.getListingSKU() &&
      params.api !== 'true'
    ) {
      this.setState({ listing: null }, () => {
        this.getListing(this.getListingSKU())
      })
    }
  }

  getListing(sku) {
    this.props.setLoading(true)
    const currentUser = JSON.parse(localStorage.getItem('login'))

    const params = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })
    const id = this.props.match.params.id || ''

    if (params.api !== 'true') {
      //needed to be change the api
      if (
        (!currentUser ||
          (currentUser.status === 'Pending' && currentUser.KYCStatus)) &&
        this.props.list.count > 3
      ) {
      } else {
        axios
          .get(`/api/listings/${id}`)
          .then((res) => {
            const { listing, sellerListingCount, similarListings } = res.data
            let listing_user = listing.user
            let taImages = listing?.taImages?.map(
              (ob) => ob['imageURL800'] || ob['imageURL1600']
            )
            listing.imageArray = [...listing.imageArray, ...taImages, ...listing?.tradeSoftImages].filter(
              (i) => i
            )
            listing.pic = listing?.imageArray[0]

            this.getReviews(listing._id)
            this.setState(
              {
                listing,
                listing_user,
                seller_listing: sellerListingCount,
                similarListings,
                totalStar: listing_user.rating,
                cartQuantity: [listing?._id]
              },
              () => {
                this.props.sameNameItemList(
                  listing?.partName,
                  listing?.type,
                  listing_user?._id
                )
                this.props.setLoading(false)
              }
            )
            if (
              !currentUser ||
              (currentUser.status === 'Pending' && currentUser.KYCStatus)
            ) {
              this.props.visitor(this.props.list.count)
            }
            if(currentUser){
              ga4Events({
                action: 'product_detail_page_view',
                category: 'Product Detail',
                data:{
                product_id: listing.id,
                platform: 'web',
                partSKU:listing.partSKU,
                partName:listing.partName,
                product_category:listing.category.name,
                product_subcategory:listing.subCategory.name,
                partBrand:listing.partBrand,
                type:listing.type,
                countryOrigin:listing.countryOrigin,
                currency:listing.currency,
                price:listing.price,
                inventoryStock:listing.inventoryStock,
                business_model:listing.business_model,
                page_type:"Product_Detail",
                page_url:window.location.href,
                related_products_count:listing.tags.length,
                referrer_section:"Product_List",
                referrer_section_name: "",
                referrer_page:"Listing_Page",
                referrer_page_url:JSON.parse(localStorage.getItem('listingUrl'))
        
                }      })
        
            }
            
          })
          .catch((err) => {
            this.props.setLoading(false)
            this.props.history.push('/')
            popUp('There was a problem with server. Please try again.')
          })
      }
    } else {
      const info = this.props.match.params.info.split('--')
      const listingOtherInfo = info.pop().split('-')
      const carID = listingOtherInfo.pop()
      const articleNumber = id

      if (
        (!currentUser ||
          (currentUser.status === 'Pending' && currentUser.KYCStatus)) &&
        this.props.list.count > 3
      ) {
      } else {
        axios
          .get(`/api/info/get-article-detail?articleNumber=${id}`)
          .then((res) => {
            let { bp24, articles } = res.data
            const newListings = articles.filter((d) => {
              return (
                d.articleNumber.toLowerCase() === articleNumber.toLowerCase()
              )
            })

            let newListing
            if (newListings.length > 0) {
              newListing = {
                category: '',
                clickCollect: '',
                countryOrigin: '',
                currency: '',
                date: '',
                delivery: '',
                depthDimension: '',
                description:
                  newListings[0].genericArticles[0].genericArticleDescription,
                fittingPosition: '',
                heightDimension: '',
                hide: '',
                makes: newListings[0].oemNumbers
                  ?.map((oem) => oem.mfrName)
                  ?.join('/'),
                models: '',
                partBrand: newListings[0].mfrName,
                partHSCode: '',
                partName: `${newListings[0].mfrName} ${newListings[0].genericArticles[0].genericArticleDescription}`,
                partSKU: `${newListings[0].articleNumber}`,
                pic: newListings[0].images[0]?.imageURL800,
                price: '',
                priceExclVat: '',
                quantity: '',
                subCategory: '',
                type: '',
                user: '',
                weight: '',
                widthDimension: '',
                __v: '',
                _id: '',
              }
            }
            this.setState({ listing: newListing })
            if (
              !currentUser ||
              (currentUser.status === 'Pending' && currentUser.KYCStatus)
            ) {
              this.props.visitor(this.props.list.count)
            }
            this.props.setLoading(false)
          })
          .catch((err) => {
            popUp('There was a problem with server. Please try again.')
          })
      }
    }
  }

  openModal() {
    this.setState({ modalIsOpen: true })
  }

  closeModal() {
    this.setState({ modalIsOpen: false })
  }

  qtyIncrement(e, listing) {
    e.preventDefault();
    const items = this.props.list.itemsInCart;
    const currentUser = JSON.parse(localStorage.getItem('login'));
    if ((this.apiCallCount < this.apiCallLimit)) {
      this.apiCallCount += 1;
      if (!items.includes(listing?._id)) {
        axios
          .get(
            `/api/listings/check-buyability?emirate=${currentUser?.details?.emirate}&list=${listing?._id}`
          )
          .then((res) => {
            const { canbuy } = res.data
            if (canbuy) {
              const cartItems = [...this.state.cartQuantity, listing?._id]
              this.setState({ cartQuantity: cartItems })
            } else {
              popUp('This seller do not sell in your emirate.')
            }
          })
          .catch((err) => {
            popUp('This seller do not sell in your emirate.')
          })
      } else {
        const cartItems = [...this.state.cartQuantity, listing?._id]
        this.setState({ cartQuantity: cartItems })
      }
      ga4Events({
        action: 'pdp_increase_quantity',
        category: 'Cart',
        product_id: listing?._id,
        product_name: listing?.partName,
        product_category: listing?.category?.name,
        product_brand: listing?.partBrand,
        product_variant: listing?.variant,
        quantity:
          getCartLength(this.props.list.itemsInCart, listing) == 0
            ? getCartLength(this.props.list.itemsInCart, listing) + 1
            : getCartLength(this.props.list.itemsInCart, listing),
        product_price: listing?.price,
        cart_value: this.props.list.itemsInCart?.length,
      })
    }

  }
  qtyDecrement(e, listing) {
    e.preventDefault()
    const items = this.state.cartQuantity
    const index = items.indexOf(listing?._id)
    if (index >= 0) items.splice(index, 1)
    this.setState({ cartQuantity: items })
    this.apiCallCount -= 1;

    ga4Events({
      action: 'pdp_decrease_quantity',
      category: 'Cart',
      product_id: listing?._id,
      product_name: listing?.partName,
      product_category: listing?.category?.name,
      product_brand: listing?.partBrand,
      product_variant: listing?.variant,
      quantity:
        getCartLength(this.props.list.itemsInCart, listing) == 0
          ? getCartLength(this.props.list.itemsInCart, listing) + 1
          : getCartLength(this.props.list.itemsInCart, listing),
      product_price: listing?.price,
      cart_value: this.props.list.itemsInCart?.length,
    })
  }
  chooseTab(e, index, action) {
    e.preventDefault()
    this.setState({ listingDetailTabIndex: index })

    ga4Events({
      action,
      category: 'Listing',
      product_id: this.state.listing?._id,
    })
  }

  requestForQuotation(name, id) {
    const { listing_user, listing } = this.state
    let { quotationSeller, quotationItems } = this.props.quotation

    if (quotationSeller === null) {
      this.handleNewQuotation(name, id)
    }
    else {
      const index = quotationItems.findIndex(
        (x) => x?.listingId === listing._id
      )

      if (index < 0) {
        quotationItems.push({
          listingId: listing._id,
          name: listing.partName,
          price: listing.price,
          priceExclVat: listing.priceExclVat,
          qty: 1,
          pic: listing.pic,
          link: `${stringToUrl(listing.partName)} + "--" + ${stringToUrl(
            listing.partSKU
          )}`,
          sellerInfo: {
            label: name,
            value: id,
          },
        })
        popUp(
          'You have created new quotation, view it now?',
          'View',
          '/quotations',
          this.props.history
        )
      } else {
        quotationItems[index].qty = quotationItems[index].qty + 1
        if (quotationItems[index].qty > 0) {
          popUp(
            ' Quotation Modified Successfully.',
            'View',
            '/quotations',
            this.props.history
          )
        } else {
          popUp('Already Sent Request For Quotation !')
        }
      }

      this.props.updateQuotationItems({
        quotationSeller,
        quotationItems,
      })
    }
  }

  handleNewQuotation(name, id) {
    const { listing_user, listing } = this.state
    let { quotationSeller, quotationItems } = this.props.quotation

    quotationSeller = listing_user?._id

    quotationItems = [
      {
        listingId: listing._id,
        name: listing.partName,
        price: listing.price,
        priceExclVat: listing.priceExclVat,
        qty: 1,
        pic: listing.pic,
        link: `${stringToUrl(listing.partName)} + "--" + ${stringToUrl(
          listing.partSKU
        )}`,
        sellerInfo: {
          label: name,
          value: id,
        },
      },
    ]
    this.props.newQuotation({
      quotationSeller,
      quotationItems,
    })
    popUp(
      'Quotation Created Successfully.',
      'View',
      '/quotations',
      this.props.history
    )
  }

  inQuotation() {
    const { listing } = this.state
    const { quotationItems } = this.props.quotation
    const index = quotationItems?.findIndex((x) => x.listingId === listing._id)
    if (index >= 0) {
      return quotationItems[index].qty
    }
    return 0
  }

  modalclose = () => {
    this.setState({
      modalshow: false,
    })
  }

  callModal = (
    imageArray,
    partName,
    partSKU,
    price,
    priceExclVat,
    _id,
    date,
    description,
    title = '',
    badge = '',
    link = '',
    url = ''
  ) => {
    this.setState({
      modalshow: true,
      imageArray: imageArray,
      partName: partName,
      partSKU: partSKU,
      price: price,
      priceExclVat: priceExclVat,
      _id: _id,
      date: date,
      description: description,
      title: partName,
      badge: badge,
      link: link,
      url: url,
      apiSendNo: partSKU,
    })
  }

  isReviewModal(res) {
    this.setState({ isOpenReviewModal: res })
  }

  submitReview = (e) => {
    e.preventDefault()
    this.setState({ disableBtn: true })
    if (this.props?.login?.role === 'SELLER') {
      return this.props.history.push('/register')
    }
    axios
      .post(`/api/review/${this.state?.listing?._id}`, {
        user: this.props?.login?._id,
        list: this.state?.listing?._id,
        comment: this.state.comment,
        star: this.state.ratingStar,
      })
      .then((res) => {
        this.getReviews(this.state?.listing?._id)
        this.setState({
          isOpenReviewModal: false,
          comment: null,
          ratingStar: null,
          disableBtn: false,
        })

        ga4Events({
          action: 'pdp_add_review',
          category: 'Listing',
          product_id: this.state?.listing?._id,
        })
      })
      .catch((err) => {
        popUp('There was a problem with server. Please try again.')
        this.setState({
          disableBtn: false,
          isOpenReviewModal: false,
          comment: null,
          ratingStar: null,
        })
      })
  }

  editReview = (e) => {
    e.preventDefault()
    this.setState({ disableBtn: true })
    if (this.props?.login?.role === 'SELLER') {
      return this.props.history.push('/register')
    }
    axios
      .patch(`/api/review/${this.state.userReviewForEdit._id}`, {
        comment: this.state.comment,
        star: this.state.ratingStar,
      })
      .then((res) => {
        this.getReviews(this.state?.listing?._id)
        this.setState({
          isOpenReviewModal: false,
          comment: null,
          ratingStar: null,
          disableBtn: false,
          totalStar: res.data.seller.rating,
        })
      })
      .catch((err) => {
        popUp('There was a problem with server. Please try again.')
        this.setState({
          disableBtn: false,
          isOpenReviewModal: false,
          comment: null,
          ratingStar: null,
        })
      })
  }

  updateComment = (e) => {
    this.setState({ comment: e.target.value })
  }

  updateRatingStar = (e) => {
    this.setState({ ratingStar: e.target.value })
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.match.params.info !== this.props.match.params.info ||
      prevProps.match.params.id !== this.props.match.params.id
    ) {
      this.setState({ listing: null }, () => {
        this.getListing(this.getListingSKU())
      })
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const items = this.props.list.itemsInCart;
    const stockArray = this.state.cartQuantity?.length && items.concat(this.state.cartQuantity);
    const result = countIdsWithQuantity(stockArray);
    const itemInCart = result.find(item => item.id === this.state.listing?._id);
    const isAvailable = this.state.listing?.quantity >= itemInCart?.quantity;
    this.apiCallLimit = this.state.listing?.quantity;

    if (prevProps.match.params.info !== this.props.match.params.info ||
      prevProps.match.params.id !== this.props.match.params.id) {
      try {
        if (isAvailable) {
          this.setState({ disableIncrement: false })
        } else {
          this.setState({ disableIncrement: true })

        }
      } catch (error) {
        console.error(error);
      }

    }

  }

  handleModal = () => {
    this.setState((state) => ({
      ...state,
      showModal: true,
    }))
  }
  handleCloseModal = () => {
    this.setState((state) => ({
      ...state,
      showModal: false,
    }))
  }

  handleNotWorkingmodal = (status) => {
    this.setState({
      modalshow: false,
    })
  }
  handleToggleDrawer(res) {
    this.setState({ isDrawerOpen: res })
  }

  handleCart = (listing) => {
    const items = this.props.list.itemsInCart;
    const stockArray = this.state.cartQuantity?.length && items.concat(this.state.cartQuantity);
    const result = countIdsWithQuantity(stockArray);
    const isAvailable = this.state.cartQuantity?.length ? checkAndLogExceedLimitForEqual(listing?._id, result, listing?.quantity) : checkAndLogExceedLimit(listing?._id, result, listing?.quantity);
    const eventData = {
      action: 'pdp_add_to_cart',
      category: 'Listing',
      product_id: listing?._id,
      product_name: listing?.partName,
      product_category: listing?.category?.name,
      product_brand: listing?.partBrand,
      product_variant: listing?.variant,
      quantity_added:
        getCartLength(this.props.list.itemsInCart, listing) === 0
          ? getCartLength(this.props.list.itemsInCart, listing) + 1
          : getCartLength(this.props.list.itemsInCart, listing),
      product_price: listing?.price,
      cart_value: this.props.list.itemsInCart?.length,
    }

    if (isAvailable) {
      popUp("Your cart items quantity exceeded stock quantity.")
    } else {
      this.apiCallCount = 1;
      if (this.state.cartQuantity.length > 0) {
        this.props.addMultipleItemsInCart(this.state.cartQuantity, eventData)
        this.setState({ cartQuantity: [listing?._id] })
        popUp('Item added Successfully')
      } else {
        this.props.addToCart(listing?._id, eventData)
      }
    }


  }

  //onClose modal
  closeVisitorModal = (e) => {
    e.preventDefault()
    this.setState({
      showVisitorModal: !this.state.showVisitorModal,
    })
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    const {
      modalIsOpen,
      listing,
      listing_user,
      seller_listing,
      reviewData,
      isOpen,
      cartQuantity
    } = this.state
    const currentUser = JSON.parse(localStorage.getItem('login'))

    const msg =
      'You need to register as a buyer to be able to make an order on BuyParts24'
    const { isLoading } = this.props.list
    const params = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })
    if (this.state.loading) return <p>Loading</p>

    let dataStar =
      reviewData.length > 0
        ? reviewData.reduce((sum, data) => sum + data.star, 0) /
        reviewData.length
        : 0

    const link =
      'https://buyparts24.com/' +
      stringToUrl(listing?.partName) +
      '--' +
      stringToUrl(listing?.partSKU) +
      `/${listing?._id}`

    const deliveryTime = getListingDeliveryTime({
      business_model: listing?.business_model,
      quantity: listing?.quantity,
      bmDTs: listing?.user?.bmDTs
    });

    const items = this.props.list.itemsInCart;
    const stockArray = items.concat(this.state.cartQuantity);
    const result = countIdsWithQuantity(stockArray);
    const itemInCart = result.find(item => item.id === listing?._id);


    return (
      <Fragment>
        {!currentUser && this.props.list.count >= 3 ? (
          <VisitorModal
            showModal={this.state.showVisitorModal}
            onClose={this.closeVisitorModal}
            msg={
              "You have exceeded your visitor's limit ! Please Login Or Register for further process."
            }
          />
        ) : null}
        <CardModal
          callbackClose={this.modalclose}
          modalstatus={this.state.modalshow}
          isForRequest={false}
          title={this.state.title}
          url={this.state.imageArray}
          badge={this.state.badge}
          link={this.state.link}
          imageArray={this.state.imageArray}
          partName={this.state.partName}
          partSKU={this.state.partSKU}
          price={this.state.price}
          _id={this.state._id}
          date={this.state.date}
          description={this.state.description}
          login={this.props.login}
          apiSendNo={this.state.apiSendNo}
          apiopenmodal={this.handleModal}
          apicloseModal={this.handleCloseModal}
          startChat={this.startChat}
          listing_user={listing_user}
        />
        <LoadingOverlay active={isLoading} spinner text='Loading listing...'>
          {!params.api && (
            <BreadcrumbCategory
              title={listing ? listing.partName : ''}
              category={listing ? listing.category : ''}
              subCategory={listing ? listing.subCategory : ''}
            />
          )}
          <Review
            isOpenReviewModal={this.state.isOpenReviewModal}
            reviewModal={this.isReviewModal}
            itemId={this.state?.listing?._id}
            loginId={this.props?.login?._id}
            addReview={this.submitReview}
            editReview={this.editReview}
            disableBtn={this.state.disableBtn}
            updateComment={this.updateComment}
            updateRatingStar={this.updateRatingStar}
            ratingStar={this.state.ratingStar}
            comment={this.state.comment}
            hasLoggedUserReviewed={this.state.hasLoggedUserReviewed}
            setClose={() => this.setState({ isOpenReviewModal: false })}
          />

          {!params.api && (
            <MultisellerDrawer
              isDrawerOpen={this.state.isDrawerOpen}
              handleToggleDrawer={this.handleToggleDrawer}
              data={this.props.list.sameNameItemList}
              addToCart={this.props.addToCart}
              staticData={listing}
              dataStar={dataStar}
              user={currentUser}
              seller={listing_user?._id}
            />
          )}

          <section className='directory_listiing_detail_area single_area section-bg pt-4'>
            {listing && (
              <div className='container'>
                <div>
                  <div className='row'>
                    <div className='col-lg-7'>
                      <div className='atbd_content_module atbd_listing_gallery'>
                        <div
                          className='atbdb_content_module_contents new-atdbd-module'
                          style={
                            listing?.imageArray?.length < 1
                              ? { textAlign: 'center' }
                              : {}
                          }>
                          {params.api === 'true' ? (
                            <>
                              {currentUser && (
                                <div
                                  onClick={() => this.toggle()}
                                  style={{
                                    top: 10,
                                    right: 10,
                                    float: 'right',
                                    position: 'relative',
                                    zIndex: 1,
                                    border: 'none',
                                    cursor: 'pointer',
                                    color: 'black',
                                    marginTop: "0px"
                                  }}>
                                  <Share />
                                </div>
                              )}
                              <ProductImage
                                avatar={listing.pic || listing.imageArray[0]}
                                alt='Listing'
                                style={{
                                  objectFit: 'cover',
                                  height: '100%',
                                  width: 430,
                                }}
                              />

                              {
                                deliveryTime ?
                                  <TextOverlay value={deliveryTime} />
                                  : null
                              }
                            </>
                          ) : listing?.imageArray?.length < 1 ? (
                            <>
                              {currentUser && (
                                <div
                                  onClick={() => this.toggle()}
                                  style={{
                                    top: 10,
                                    right: 10,
                                    float: 'right',
                                    position: 'relative',
                                    zIndex: 1,
                                    border: 'none',
                                    cursor: 'pointer',
                                    color: 'black',
                                    marginTop: "0px"
                                  }}>
                                  <Share />
                                </div>
                              )}

                              <ProductImage
                                avatar={listing.pic}
                                alt='Listing'
                                style={{
                                  objectFit: 'contain',
                                  height: '100%',
                                  width: 430,
                                }}
                              />
                              {
                                deliveryTime ?
                                  <TextOverlay value={deliveryTime} />
                                  : null
                              }
                            </>
                          ) : (
                            <Gallery
                              data={listing.imageArray}
                              business_model={listing.business_model}
                              deliveryTime={deliveryTime}
                              id={listing?._id}
                              link={link}
                              currentUser={currentUser}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-5'>
                      <div className='widget'>
                        <div className='atbd_widget_title'>
                          <div
                            className='mb-4 w-100 d-flex justify-content-between'
                            style={{ justifyContent: 'space-between' }}>
                            <h1 className='mb-2' style={{ color: '#272b41' }}>
                              {listing.partName}
                            </h1>
                            {params.api !== 'true' && listing.quantity > 0
                              ? this.props.login && (
                                <h1
                                  className={params.api}
                                  style={{
                                    color: '#F39200',
                                    display: 'flex',
                                    flexDirection: 'column',
                                  }}>
                                  <span
                                    style={{
                                      display: 'inline-block',
                                      width: 200,
                                      textAlign: 'right',
                                    }}>
                                    <ShowDynamicCurrency />
                                    &nbsp;
                                    <span>
                                      <ShowDynamicPrice
                                        price={listing?.priceExclVat}
                                        hideExc
                                      />
                                    </span>
                                  </span>
                                  <span
                                    style={{
                                      color: '#000',
                                      fontSize: 12,
                                      textAlign: 'right',
                                    }}>
                                    All prices Excluding VAT
                                  </span>
                                </h1>
                              )
                              : ''}
                          </div>
                          <div className='rating-group row mt-3 mb-3'>
                            {listing?.description ? (
                              <div className='col-md-8'>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: listing?.description?.replaceAll(
                                      '\n',
                                      '<br/>'
                                    ),
                                  }}></div>
                              </div>
                            ) : null}
                            <div className='col-md-4'>
                              <div
                                className='star-rating d-flex flex-column align-items-end'
                                style={{ marginRight: '1px' }}>
                                {!dataStar ? (
                                  <span>No Reviews Yet</span>
                                ) : (
                                  <>
                                    <StarRatingComponent
                                      name='rate2'
                                      editing={false}
                                      renderStarIcon={() => (
                                        <i className='la la-star' />
                                      )}
                                      renderStarIconHalf={() => (
                                        <i
                                          className='la la-star-half-alt'
                                          style={{ color: '#ffb400' }}
                                        />
                                      )}
                                      starColor='#ffb400'
                                      emptyStarColor={'#cecece'}
                                      starCount={5}
                                      value={dataStar.toFixed(1)}
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          {params.api !== 'true' ? (
                            <div className='d-flex detail_add_to_cart justify-content-between'>
                              <div className='align-self-center'>
                                {
                                  listing.quantity > 0 ? (
                                    <h4>In Stock</h4>
                                  ) : null
                                }
                              </div>
                              {listing.quantity > 0 ? (
                                <>
                                  <div className='d-flex justify-content-around align-items-center p-1'>
                                    <button
                                      className='btn checkout-qty border  bg-white'
                                      disabled={
                                        currentUser?.role === 'SELLER' ||
                                        listing.quantity < 1 ||
                                        this.state.cartQuantity?.length <= 1
                                      }
                                      onClick={(e) =>
                                        this.qtyDecrement(e, listing)
                                      }>
                                      -
                                    </button>
                                    <span
                                      className='border h-100 qtn_item_checkout  justify-content-center d-flex align-items-center bg-white'
                                      style={{ width: '50px' }}>
                                      {
                                        this.state.cartQuantity?.length
                                      }
                                    </span>
                                    <button
                                      className='btn checkout-qty border bg-white'
                                      disabled={
                                        currentUser?.role === 'SELLER' ||
                                        this.state.disableIncrement ||
                                        (this.state.listing?.quantity <= itemInCart?.quantity)
                                      }
                                      onClick={(e) => {
                                        this.qtyIncrement(e, listing)
                                      }
                                      }>
                                      +
                                    </button>
                                  </div>
                                  <div>
                                    {currentUser ? (
                                      <div>
                                        {
                                          listing?.quantity > 0 ? (
                                            <button
                                              className='btn btn-block'
                                              style={{
                                                backgroundColor: '#f39200',
                                                color: 'white',
                                                marginLeft: '0px',
                                              }}
                                              disabled={this.props.list.isAddToCartLoading}
                                              onClick={(e) => {
                                                this.handleCart(listing)
                                                localStorage.setItem(
                                                  'filterPath',
                                                  JSON.stringify(
                                                    this.props.location.pathname
                                                  )
                                                )

                                                this.props.setPathname(
                                                  this.props.location.pathname
                                                )
                                              }}>
                                              ADD TO CART
                                            </button>
                                          ) : null
                                        }
                                      </div>
                                    ) : (
                                      <button
                                        className='btn btn-block'
                                        style={{
                                          backgroundColor: '#f39200',
                                          color: 'white',
                                          marginLeft: 0,
                                        }}
                                        disabled={this.props.list.isAddToCartLoading}
                                        onClick={this.handleModal}>
                                        ADD TO CART
                                      </button>
                                    )}
                                  </div>
                                </>
                              ) : null}
                            </div>
                          ) : (
                            <button
                              onClick={() =>
                                this.callModal(
                                  listing.pic,
                                  listing.partName,
                                  listing.partSKU,
                                  listing.price,
                                  listing._id,
                                  listing.date,
                                  listing.description
                                )
                              }
                              className='btn btn-primary btn-block mt-4'>
                              On Request
                            </button>
                          )}
                        </div>
                      </div>

                      <div>
                        {listing_user ? (
                          <div
                            className='seller-info d-flex mt-3 border-top pt-3 pb-3'
                            style={{ width: '100%' }}>
                            {/* {!(
                              listing?.business_model === 'Consolidation' &&
                              listing?.type === 'Aftermarket'
                            ) && ( */}
                            <div
                              className='seller-information-contianer w-100'
                              style={{ marginLeft: '-4px' }}>
                              {
                                listing_user?.aliasName ?
                                  <div
                                    className='seller-name-with-icon'
                                    style={{ display: 'flex' }}
                                  >
                                    <div
                                      style={{ margin: 'auto 0', color: '#272b41' }}
                                    >
                                      <span
                                        className='la la-user'
                                        style={{
                                          fontSize: 20,
                                        }}
                                      />
                                    </div>
                                    <div
                                      className='seller-name'
                                      style={{
                                        fontSize: 18,
                                        color: '#272b41',
                                        marginLeft: '10px',
                                        fontWeight: '700',
                                      }}
                                    >
                                      {/* <NavLink
                                      to={`/stores/${listing_user?.slug}`}
                                      onClick={() =>
                                        ga4Events({
                                          action: 'pdp_seller_name_click',
                                          category: 'User',
                                          seller_id: listing_user?._id,
                                          seller_name:
                                            listing_user?.details?.company_name,
                                        })
                                      }
                                    > */}
                                      {listing_user?.aliasName}
                                      {/* </NavLink> */}

                                    </div>
                                  </div> : null
                              }
                              <div
                                className='seller-location d-flex'
                                style={{ marginTop: '1rem' }}>
                                <LocationOnIcon
                                  style={{ fontSize: 16, marginRight: '8px', marginTop: "0px" }}
                                />{' '}
                                {listing_user?.details?.emirate}
                              </div>
                            </div>
                            {/* )} */}

                            {this.state.totalStar > 1 ? (
                              <div
                                className='ml-auto mt-1'
                                style={{ width: '20%', marginRight: '-2px' }}>
                                <span className='author-rating bg-success'>
                                  {this.state.totalStar.toFixed(1)}
                                  <i className='mt-1 ml-1 la la-star'></i>
                                </span>
                              </div>
                            ) : (
                              <span style={{ width: '150px' }}>
                                No Review yet
                              </span>
                            )}
                          </div>
                        ) : (
                          <div className='seller-info d-flex mt-4 border-top pt-2'>
                            <div className='seller-auth-info w-100 mr-auto mb-4'>
                              <p className='mb-0' style={{ color: '#272b41' }}>
                                <img
                                  src='/assets/img/logo.svg'
                                  alt='logoImage'
                                  className='img-fluid'
                                  width='105'
                                  style={{ marginTop: 10, marginBottom: 12 }}
                                />
                              </p>
                              <span
                                className='h6'
                                style={{
                                  color: '#272b41',
                                  fontSize: 14,
                                }}>
                                This product is currently not sold by any seller
                                on the marketplace. If you are looking for this
                                particular product, please make a request to our
                                sales team.
                              </span>
                            </div>
                          </div>
                        )}
                        {listing_user && (
                          <div className='d-flex jusify-content-between detail-btns'>
                            <div
                              className='w-100 p-1'
                              style={{ marginLeft: '-3px' }}>
                              {currentUser ? (
                                <button
                                  className='btn btn-primary btn-block'
                                  onClick={(e) => this.startChat(e)}>
                                  Start Discussion
                                </button>
                              ) : (
                                <button
                                  className='btn btn-primary btn-block'
                                  onClick={this.handleModal}>
                                  Start Discussion
                                </button>
                              )}
                            </div>
                            <div className='w-100 p-1'>
                              <button
                                className='btn btn-block'
                                style={{
                                  backgroundColor: '#f39200',
                                  color: 'white',
                                  marginLeft: 3,
                                }}
                                onClick={(e) => {
                                  this.requestForQuotation(
                                    listing_user?.details?.company_name,
                                    listing_user?._id
                                  )
                                  ga4Events({
                                    action: 'pdp_request_for_quotation',
                                    category: 'Listing',
                                    product_id: listing?._id,
                                    product_name: listing?.partName,
                                  })
                                }}
                                disabled={
                                  this.props?.login?.role === 'SELLER' ||
                                  (this.props?.login?.role == null && true)
                                }>
                                {this.inQuotation() > 0
                                  ? 'Add Quotation Quantity (' +
                                  this.inQuotation() +
                                  ')'
                                  : 'Request for Quotation'}
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='row mt-4 listing-detail-navbar'
                  style={{ backgroundColor: 'transparent' }}>
                  <div className='col-lg-12'>
                    <div
                      className='listing-detail-nav'
                      style={{ backgroundColor: '#e5eff5' }}>
                      <ul className='nav'>
                        <li
                          className={`nav-item ${this.state.listingDetailTabIndex === 1
                              ? 'active'
                              : ''
                            }`}>
                          <a
                            className='nav-link'
                            href='#!'
                            onClick={(e) =>
                              this.chooseTab(e, 1, 'pdp_description_click')
                            }>
                            Description
                          </a>
                        </li>

                        <li
                          className={`nav-item ${this.state.listingDetailTabIndex === 2
                              ? 'active'
                              : ''
                            }`}>
                          <a
                            className='nav-link'
                            href='#!'
                            onClick={(e) =>
                              this.chooseTab(e, 2, 'pdp_specification_click')
                            }>
                            Specification
                          </a>
                        </li>
                        <li
                          className={`nav-item ${this.state.listingDetailTabIndex === 11
                              ? 'active'
                              : ''
                            }`}>
                          <a
                            className='nav-link'
                            href='#!'
                            onClick={(e) =>
                              this.chooseTab(e, 11, 'pdp_oemNumber_click')
                            }>
                            OEM Numbers
                          </a>
                        </li>
                        <li
                          className={`nav-item ${this.state.listingDetailTabIndex === 8
                              ? 'active'
                              : ''
                            }`}>
                          <a
                            className='nav-link'
                            href='#!'
                            onClick={(e) =>
                              this.chooseTab(
                                e,
                                8,
                                'pdp_vehicle_make_model_click'
                              )
                            }>
                            Vehicle Model/Make
                          </a>
                        </li>
                        <li
                          className={`nav-item ${this.state.listingDetailTabIndex === 7
                              ? 'active'
                              : ''
                            }`}>
                          <a
                            className='nav-link'
                            href='#!'
                            onClick={(e) =>
                              this.chooseTab(
                                e,
                                7,
                                'pdp_service_description_click'
                              )
                            }>
                            Service Description
                          </a>
                        </li>
                        <li
                          className={`nav-item ${this.state.listingDetailTabIndex === 6
                              ? 'active'
                              : ''
                            }`}>
                          <a
                            className='nav-link'
                            href='#!'
                            onClick={(e) =>
                              this.chooseTab(e, 6, 'pdp_additional_notes_click')
                            }>
                            Additional Notes
                          </a>
                        </li>
                        {params.api !== 'true' && (
                          <li
                            className={`nav-item ${this.state.listingDetailTabIndex === 3
                                ? 'active'
                                : ''
                              }`}>
                            <a
                              className='nav-link'
                              href='#!'
                              onClick={(e) =>
                                this.chooseTab(e, 3, 'pdp_reviews_click')
                              }>
                              Reviews
                            </a>
                          </li>
                        )}
                        {params.api === 'true' && (
                          <li
                            className={`nav-item ${this.state.listingDetailTabIndex === 5
                                ? 'active'
                                : ''
                              }`}>
                            <a
                              className='nav-link'
                              onClick={() =>
                                this.callModal(
                                  listing.pic,
                                  listing.partName,
                                  listing.partSKU,
                                  listing.price,
                                  listing._id,
                                  listing.date,
                                  listing.description
                                )
                              }>
                              On Request
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='row mt-4'>
                  <div className='col-lg-12'>
                    <div className='listing-detail-content'>
                      {this.state.listingDetailTabIndex === 1 ? (
                        <div className='atbd_content_module atbd_listing_details'>
                          <div className='atbd_content_module__tittle_area'>
                            <div className='atbd_area_title'>
                              <h4>
                                <span className='la la-file-text-o'></span>
                                Description
                              </h4>
                            </div>
                          </div>
                          <div className='atbdb_content_module_contents'>
                            {listing?.description ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: listing.description.replaceAll(
                                    '\n',
                                    '<br/>'
                                  ),
                                }}
                              />
                            ) : null}
                          </div>
                        </div>
                      ) : this.state.listingDetailTabIndex === 2 ? (
                        <ListingFetures
                          listing={listing}
                          login={this.props.login}
                        />
                      ) : this.state.listingDetailTabIndex === 6 ? (
                        <AdditionalNotes listing={listing} />
                      ) : this.state.listingDetailTabIndex === 7 ? (
                        <ServiceDescription listing={listing} />
                      ) : this.state.listingDetailTabIndex === 8 ? (
                        <ListingManufacture listing={listing} />
                      ) : this.state.listingDetailTabIndex === 11 ? (
                        <div className='atbd_content_module atbd_listing_details'>
                          <div className='atbd_content_module__tittle_area'>
                            <div className='atbd_area_title'>
                              <h4>OEM Numbers</h4>
                            </div>
                          </div>
                          <div
                            className='atbdb_content_module_contents'
                            style={{
                              maxHeight: '300px',
                              overflowY: 'scroll',
                              paddingTop: 10,
                            }}>
                            {listing?.oemNumbers.map((item, index) => (
                              <div key={index}>
                                <button
                                  onClick={() => {
                                    popUp('Copied !')
                                    navigator.clipboard.writeText(
                                      item.articleNumber
                                    )
                                  }}
                                  className='btn part-sku'
                                  style={{
                                    whiteSpace: 'pre-wrap',
                                    padding: 0,
                                  }}>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    height='18'
                                    viewBox='0 0 24 24'
                                    width='24'>
                                    <path d='M0 0h24v24H0z' fill='none' />
                                    <path d='M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z' />
                                  </svg>
                                </button>
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    color: '#000',
                                  }}>
                                  {' '}
                                  {item.articleNumber}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className='atbd_content_module atbd_listing_details'>
                            <div className='atbd_content_module__tittle_area'>
                              <div
                                className='atbd_area_title'
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}>
                                <h4>
                                  <span className='la la-file-text-o'></span>
                                  Reviews
                                </h4>

                                {currentUser ? (
                                  currentUser?.role !== 'SELLER' &&
                                  (!this.state.hasLoggedUserReviewed ? (
                                    <button
                                      className='btn btn-color-blue'
                                      type='submit'
                                      id='atbdp_review_form_submit'
                                      onClick={() => {
                                        if (
                                          this.props?.login?.role === 'SELLER'
                                        ) {
                                          return this.props.history.push(
                                            '/register'
                                          )
                                        }
                                        this.setState({
                                          isOpenReviewModal: true,
                                        })
                                      }}>
                                      {' '}
                                      Add
                                    </button>
                                  ) : (
                                    <button
                                      className='btn btn-color-blue'
                                      type='submit'
                                      id='atbdp_review_form_submit'
                                      onClick={() => {
                                        if (
                                          this.props?.login?.role === 'SELLER'
                                        ) {
                                          return this.props.history.push(
                                            '/register'
                                          )
                                        }
                                        this.setState({
                                          isOpenReviewModal: true,
                                        })
                                      }}>
                                      {' '}
                                      Edit
                                    </button>
                                  ))
                                ) : (
                                  <button
                                    className='btn btn-color-blue'
                                    type='submit'
                                    id='atbdp_review_form_submit'
                                    onClick={this.handleModal}
                                  >
                                    {' '}
                                    Add
                                  </button>
                                )}
                              </div>
                            </div>
                            <div className='atbdb_content_module_contents'>
                              {this.state.reviewData.length === 0 ? (
                                <p style={{ textAlign: 'center' }}>
                                  <img
                                    src='/assets/img/no-review.png'
                                    style={{ width: '20%' }}
                                  />
                                </p>
                              ) : (
                                <ItemReview data={this.state.reviewData} />
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {params.api !== 'true' && (
                  <div className='row mt-4'>

                    <div className='col-lg-12 mb-2'>
                      <h1 className='mb-3'>Customer also Viewed </h1>
                      <section
                        className='listing-cards homeListing_card'
                        style={{ marginLeft: '-15px' }}>
                        <div className='container'>
                          <div className='row'>
                            <div className='detail_listing_cards home-listing-cards-wrapper col-lg-12'>
                              <div>
                                {this.state?.similarListings?.length ||
                                  this.state?.similarListings?.length > 0 ? (
                                  <LikeListingGrid
                                    listings={this.state.similarListings}
                                  />
                                ) : (
                                  <div
                                    style={{
                                      textAlign: 'center',
                                      alignItems: 'center',
                                    }}>
                                    <img
                                      src='/assets/img/noproduct.png'
                                      style={{
                                        height: '300px',
                                        width: '300px',
                                      }}
                                    />
                                    <h3 style={{ color: '#1b0451' }}>
                                      Coming Soon....
                                    </h3>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                )}
              </div>
            )}
          </section>
          <Modal
            visible={modalIsOpen}
            width='600'
            effect='fadeInUp'
            onClickAway={() => this.closeModal()}>
            <CallbackDetails
              listing_id={listing ? listing._id : null}
              closeModal={this.closeModal}
            />
          </Modal>
        </LoadingOverlay>
        <NotLoggedInModal
          showModal={this.state.showModal}
          onClose={this.handleCloseModal}
          msg={msg}
        />

        <ShareModal
          isModalOpen={isOpen}
          toggle={this.toggle}
          id={listing?._id}
          link={link}
        />
      </Fragment>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    list: state.list,
    login: state.login,
    quotation: state.quotation,
    currency: state.currency,
  }
}

const mapDispatchToProp = (dispatch) => {
  return {
    setLoading: (data) => dispatch(SetLoading(data)),
    setChatRoom: (data) => dispatch(SetActiveRoom(data)),
    chatRoomInitialize: () => dispatch(Initialize()),
    addToCart: (data, eventData) => dispatch(AddToCart(data, eventData)),
    updateCart: (data) => dispatch(UpdateCart(data)),
    addMultipleItemsInCart: (data, eventData) => dispatch(addMultipleItemsInCart(data, eventData)),
    newQuotation: (data) => dispatch(NewQuotation(data)),
    updateQuotationItems: (data) => dispatch(UpdateQuotationItems(data)),
    clearQuotation: (data) => dispatch(ClearQuotation(data)),
    sameNameItemList: (name, type, seller) =>
      dispatch(getSameNameItem(name, type, seller)),
    visitor: (data) => dispatch(visitor(data)),
    setPathname: (pathname) => dispatch(setPathname(pathname)),
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProp)
)(ListingDetails)
