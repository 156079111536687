import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Card, CardContent, CardMedia, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import NotLoggedInModal from "../modal/not-logged-modal";
import { checkAndLogExceedLimit, countIdsWithQuantity, ga4Events, popUp, ProductImage } from "../../../../utils";
import ShowDynamicCurrency from "../../../common/ShowDynamicCurrency";
import ShowDynamicPrice from "../../../common/ShowDynamicPrice";
import { AddToCart } from "../../../../Store/action/listingActions";
import { handRequestForPrice } from "./card-listing-grid";
import TextOverlay from "../../../common/TextOverlay";
import "./PurchaseCard.css";

const useStyle = makeStyles((theme) => ({
	overlay: {
		top: '4px',
		position: 'absolute',
		color: 'white',
		padding: '3px 10px',
		fontSize: '12px',
		borderRadius: '0px 0px 12px 0px'
	},
	overlayGenuine: {
		backgroundColor: '#333333',
	},
	overlayAfterMarket: {
		backgroundColor: '#485696',
	},

}))

export default ({
	link,
	url,
	badge,
	title,
	type,
	_id,
	quantity,
	pic,
	partSKU,
	partBrand,
	category,
	partName,
	price,
	priceExclVat,
	eventAction,
	deliveryTime,
	aliasName
}) => {
	const classes = useStyle();
	const [showModal, setShowModal] = useState(false)
	const [isCopied, setIsCopied] = useState(false);
	const [msg, setErrMsg] = useState("");
	const [imageError, setImageError] = useState(false);

	const handleImageError = () => {
		setImageError(true);
	}

	const currentUser = JSON.parse(localStorage.getItem("login"));

	const { allCategories, quotationItems, login, isAddToCartLoading } = useSelector(state => {
		return {
			allCategories: state.category.allCategories,
			quotationItems: state.quotation.quotationItems,
			login: state.login,
			isAddToCartLoading: state.list.isAddToCartLoading
		}
	});
	const { itemsInCart } = useSelector((state) => state.list)
	const dispatch = useDispatch();


	const handleCloseModal = () => {
		setShowModal(false);
	};

	useEffect(() => {
		setTimeout(() => {
			setIsCopied(false);
		}, 3000);

	}, [isCopied]);

	const handleAddToCart = () => {
		if (currentUser) {
			const eventData = {
				action: `${eventAction}_add_to_cart`,
				category: "Cart",
				product_id: _id,
			}
			dispatch(AddToCart(_id, eventData));
		} else {
			setErrMsg(
				"You need to register as a buyer to be able to make an order on BuyParts24"
			);
			setShowModal(true);
		}
	}

	const handleNewQuotation = () => {
		if (login) {
			handRequestForPrice(
				quotationItems,
				{ _id, partName, priceExclVat, qty: 1, pic },
				dispatch
			)
		} else {
			popUp('You need to register as a buyer to be able to request price')
		}
	}
	let handleGA = (e) => {
		localStorage.setItem('buyAgain',JSON.stringify(e.currentTarget.dataset.buy))
        if(localStorage.getItem('buyAgain')){
            localStorage.removeItem('categoryContainer')
			
        }
       
    };
	const result = countIdsWithQuantity(itemsInCart);
	const isAvailable = checkAndLogExceedLimit(_id, result, quantity);

	return (
		<>
			<NotLoggedInModal
				showModal={showModal}
				onClose={handleCloseModal}
				msg={msg}
			/>
			<Card sx={{ maxWidth: 345 }} className="product-slider-card">
				<NavLink to={`/spare-part-details/${link}`}  data-buy ='buyAgain' onClick={(e) => {
                            handleGA(e);}}>

					<CardMedia
						component="img"
						height="194"
						src={imageError ? "/assets/img/byparts.jpg" : url}
						onError={handleImageError}
					/>
					{
						deliveryTime ?
							<TextOverlay value={deliveryTime} />
							:
							null
					}
					<Typography variant="body1" className={`${classes.overlay} ${type == 'Genuine' ? classes.overlayGenuine : classes.overlayAfterMarket}`}>
						{type}
					</Typography>
				</NavLink>

				<CardContent>
					<NavLink className={"cursur-pointer"}
						style={{
							color: "black"
						}}
						to={`/all-listings?brand=${partBrand
							?.toLocaleLowerCase()
							?.replace(" ", "-")}`} data-buy ='buyAgain' onClick={(e) => {
								handleGA(e);}}
					>
						<span className="sku-subhead">
							<span style={{ marginRight: '5px' }}>{partBrand?.toUpperCase()}</span>
							<span className="dot"></span>
							<span style={{ marginLeft: '5px' }}>{partSKU}</span>
						</span>
					</NavLink>
					<NavLink to={`/spare-part-details/${link}`} data-buy ='buyAgain' onClick={(e) => {
                            handleGA(e);}}>
						<div className="sku-heading title-limit" >
							{title}
						</div>
					</NavLink>
					{quantity && quantity > 0 ?
						<>
							<ShowDynamicCurrency />
							<ShowDynamicPrice price={priceExclVat} hideExc />
						</>
						: (
							<div style={{ height: '23px' }}></div>
						)}
					{/* <p className="sku-warranty">3 years warranty</p> */}
					<div className="sku-cart">
						{quantity && quantity > 0 ? (
							<button
								type="button"
								className="btn search-btn request-cart-btn"
								style={{height: '32px'}}
								disabled={currentUser?.role == "SELLER" || isAddToCartLoading}
								onClick={() => {
									if (isAvailable) {
										popUp("Your cart items quantity exceeded stock quantity!")
									} else {
										handleAddToCart();
									}
								}}
							>
								ADD TO CART
							</button>)
							:
							<button
								type="button"
								onClick={handleNewQuotation}
								className="btn search-btn request-cart-btn"
								style={{height: '32px', backgroundColor: "#1d71b8",
								color: "white"}}
								
							>
								REQUEST FOR PRICE
							</button>
						}
					</div>
				</CardContent>
			</Card>
		</>

	);
};
